.l-footer {
  height: 64px;
  border-top: 1px solid $color-border;
  @include tablet {
    height: auto;
  }
  &__inner {
    @extend %layout-column;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
    font-size: $font-size-default;
    align-items: center;
    height: 100%;
    @include tablet {
      grid-template-columns: 1fr;
      padding: 20px 8px;
      justify-content: center;
      height: 100%;
      box-sizing: border-box;
      overflow: auto;
    }
    a {
      text-decoration: none;
      color: $color-blue;
    }
    .logo {
      color: rgba($color-text-body, 0.4);
      display: flex;
      align-items: center;
      height: 100%;
      @include tablet {
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-border;
      }
      a {
        height: 100%;
      }
      img {
        max-width: 80px;
        margin-right: 8px;
        opacity: .5;
      }
    }
    .links {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      font-size: $font-size-small;
      margin: auto;
      @include tablet {
        flex-direction: column;
        justify-content: center;
        padding: 20px 0;
      }
      li {
        margin: 0 16px;
        @include tablet {
          display: inline-block;
          margin: auto;
          line-height: $line-height-default;
        }
      }
    }
    .copyright {
      color: rgba($color-text-default, 0.4);
      font-size: $font-size-default;
      text-align: right;
      @include tablet {
        text-align: center;
      }
    }
  }
}
