.c-data-tabs {

  &__tabs {
    border: none;
    margin-bottom: 32px;
    @include mobile {
      white-space: nowrap;
      overflow-x: scroll;
    };
    li {
      @include mobile {
        display: inline-block;
      };
    }
    .tab[role="tab"] {
      color: $color-blue;
      line-height: 56px;
      padding: 0 12px 0;
      opacity: 0.4;
      border: none;
      border-bottom: 1px dashed transparent;
      background: transparent;
      &:focus {
        outline: none;
      }
      &:hover {
        background: inherit;
        border-color: $color-blue;
        opacity: 1;
        .label {
          font-weight: bold;
        }
      }
      .label {
        font-size: $font-size-default;
        vertical-align: middle;
      }
      .icon {
        vertical-align: middle;
        margin-right: 8px;
      }
    }
    .tab[role="tab"][aria-selected="true"] {
      border-color: $color-blue;
      opacity: 1;

      .label {
        font-weight: bold;
      }
    }
  }
  .item {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
    grid-column-gap: 48px;
    align-items: center;

    &[hidden] {
      display: none;
    }
    .image {
      width: 100%;
    }
    .content {
      color: $color-blue;
      .title {
        font-size: $font-size-large;
        margin: auto;
      }
      .subtitle {
        font-size: $font-size-medium;
        line-height: $line-height-default;
        margin: 8px auto 16px;
      }
      .detail {
        @extend %text-body;
      }
    }
  }
  @include mobile {
    .item {
      grid-template-rows: auto;
      grid-template-columns: auto;
      grid-row-gap: 40px;

      .content {
        .title {
          font-size: $font-size-medium;
        }
        .subtitle {
          font-size: $font-size-base;
        }
        .detail {

        }
      }
    }
  };
}
