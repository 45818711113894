.c-numbered-list {
  max-width: $size-col-max-width;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 48px;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
  &__item {
    padding-left: 20px;
    box-sizing: border-box;
    border-left: 8px solid #fff;
    .lead {
      font-weight: bold;
      font-size: $font-size-medium;
      margin: 0 auto 8px;
      span {
        font-size: $font-size-xlarge;
        font-weight: 900;
        margin-right: 4px;
      }
    }
    .detail {
      font-size: $font-size-default;
      line-height: $line-height-default;
      opacity: 0.9;
      margin: auto;
    }
  }
}

@include mobile {
  .c-numbered-list {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: 24px;
    &__item {
      .lead {
        font-size: $font-size-base;
        span {
          font-size: $font-size-large;
        }
      }
    }
  }
}
