.l-nav {
  width: 100%;
  &__inner {
    @extend %layout-column;
    padding-top: 4px;
    display: grid;
    grid-template-columns: auto auto;
    position: relative;
    height: 80px;
    align-items: center;
    @include tablet {
      padding: 0 16px;
    };
    &:before {
      @extend %gradient-h;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: block;
      max-width: $size-col-max-width;
      height: 4px;
    }
    .logo {
      justify-content: flex-start;
      align-items: center;
      display: grid;
      height: 100%;
    }
    a.logo-image {
      display: flex;
      text-decoration: none;
      color: $color-blue;
      img {
        width: 200px;
        @include tablet {
          width: 140px;
        };
      }
    }
    .buttons {
      justify-self: flex-end;
    }
    .button {
      height: 40px;
      line-height: 40px;
      display: inline-block;
      border-radius: 20px;
      font-size: $font-size-default;
      padding: 0 24px;
      text-align: center;
      text-decoration: none;
      transition: all 0.25s;
      @include mobile {
        padding: 0 16px;
      };
      &--primary {
        @extend %gradient-v;
        color: #fff;
        margin-left: 12px;
        box-shadow: 0px 0px 12px rgba(33, 150, 243, 0.2);
        &:hover {
          box-shadow: 0;
          opacity: 0.75;
        }
        @include mobile {
          margin-left: 6px;
        };
      }
      &--secondary {
        background-color: rgba($color-blue, 0.1);
        color: $color-blue;
        &:hover {
          background-color: rgba($color-blue, 0.2);
        }
      }
    }
  }
}
