/* Font sizes */
$font-size-xxlarge: 3rem; // 48px
$font-size-xlarge: 2rem; // 36px
$font-size-large: 1.5rem; // 24px
$font-size-medium: 1.25rem; // 20px
$font-size-base: 1rem; // 16px
$font-size-default: 0.875rem; // 14px
$font-size-small: 0.75rem; // 12px
$font-size-smaller: 0.625rem; // 10px

/* Line Heights */
$line-height-wide: 1.8em;
$line-height-default: 1.6em;
$line-height-compact: 1.3em;
/* Sizes */
$size-col-max-width: 1200px;
$size-col-medium-width: 1040px;
$size-col-text: 700px;

/* Colors */
$color-blue: #2196F3;
$color-text-default: #2A3039;
$color-text-body: rgba($color-text-default, 0.6);
$color-border: #eee;



@mixin gradient-h {
  background: linear-gradient(41.64deg, #00BCD4 0.06%, #2196F3 100.01%);
}

%gradient-h {
  @include gradient-h;
}

%gradient-v {
  background: linear-gradient(11.18deg, #00BCD4 0.06%, #2196F3 100.01%);
}

%gradient-light-h {
  background: linear-gradient(90deg, #FFFFFF 0%, #EEF6FC 100%), #2196F3;
}

%gradient-light-v {
  background: linear-gradient(180deg, #FFFFFF 6.9%, #EEF6FC 100%);
}

@mixin glow {
  box-shadow: 0px 0px 12px rgba($color-blue, 0.2);
}

%glow {
  @include glow;
}

/* Media Query */
@mixin mobile {
  @media only screen and (max-width: 640px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 968px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 641px) {
    @content;
  }
}

/* Type */
%fontFamily-default {
  font-family: 'Noto Sans JP', 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', '游ゴシック', 'メイリオ', meiryo, sans-serif;
}

%font-Poppins {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

%text-body {
  font-size: $font-size-default;
  color: $color-text-body;
  line-height: $line-height-default;
}

%text-section-tag {
  @extend %font-Poppins;
  font-size: $font-size-medium;
  color: $color-blue;
  text-align: center;
  margin: 0 auto 8px;
}

%text-section-title {
  color: $color-text-default;
  font-size: $font-size-large;
  line-height: $line-height-default;
  font-weight: bold;
  text-align: center;
  margin: 0 auto 48px;
  @include tablet {
    font-size: $font-size-large;
    br {
      display: none;
    }
  };
  @include mobile {
    margin: 0 auto 24px;
    font-size: $font-size-medium;
  };
}

/* Layout */

%layout-column {
  max-width: $size-col-max-width;
  margin: auto;
  @include tablet {
    box-sizing: border-box;
    max-width: 100%;
  };
}
%layout-section {
  padding: 80px 0;
  @include tablet {
    padding: 40px 24px;
  };
  @include mobile {
    padding: 40px 16px;
  };
}

%layout-column-medium {
  max-width: $size-col-medium-width;
  margin: auto;
  @include tablet {
    box-sizing: border-box;
    max-width: 100%;
  };
}
