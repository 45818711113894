.c-icon-list {
  @extend %layout-column-medium;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  padding: 0;
  list-style: none;

  &__item {
    display: block;
    .icon {
      width: 100%;
    }
    .title {
      font-size: $font-size-base;
      font-weight: bold;
      color: $color-blue;
      text-align: center;
      margin: 16px 0 8px;
    }
    .description {
      @extend %text-body;
      color: $color-text-default;
      margin: auto;
    }
  }
}

@include mobile {
  .c-icon-list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 16px;
  }
};
