@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import "normalize.css";
@import "tabbyjs/dist/css/tabby-ui.css";
/* Foundation */
@import "foundation/variables";
@import "foundation/mixins";
/* Layouts */
@import "layout/footer";
@import "layout/nav";
/* Components */
@import "component/data-tabs";
@import "component/icon-list";
@import "component/numbered-list";

body {
  @extend %fontFamily-default;
}

.l-header {
  @include tablet {
    padding: 40px 16px;
  }

  &__inner {
    @extend %layout-column;
    @extend %gradient-h;
    display: grid;
    grid-template-columns: 44fr 54fr;
    grid-column-gap: 40px;
    padding: 40px 80px;
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    .image {
      img {
        width: 100%;
      }
    }
    .content {
      color: #fff;
      .title {
        font-size: $font-size-large;
        font-weight: normal;
        margin: auto;
      }
      .subtitle {
        font-size: $font-size-xlarge;
        line-height: $line-height-default;
        margin: 4px auto 16px;
        font-weight: bold;
      }

      .description {
        font-size: $font-size-base;
        opacity: 0.7;
        line-height: $line-height-default;
        margin: auto;
      }
      a.button {
        @extend %glow;
        margin: 16px 0;
        display: inline-block;
        line-height: 44px;
        background-color: #fff;
        border-radius: 22px;
        width: 260px;
        font-size: $font-size-base;
        color: $color-blue;
        text-decoration: none;
        text-align: center;
        transition: all 0.25s;
        &:hover {
          opacity: 0.75;
          box-shadow: none;
        }
      }
    }
  }
}

@include tablet {
  .l-header {
    padding-top: 0;
    &__inner {
      background-color: inherit;
      background: inherit;
      grid-template-columns: auto;
      padding: 0;
      .image {
        @include gradient-h;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 8px;
        order: 0;
      }
      .content {
        padding: 16px 0 40px;
        order: 1;
        .title {
          color: $color-blue;
          font-size: $font-size-base;
        }
        .subtitle {
          color: $color-text-default;
          font-size: $font-size-medium;
          margin: 4px auto 8px;
          br {
            display: none;
          }
        }
        .description {
          color: $color-text-body;
          font-size: $font-size-default;
          opacity: 1;
        }
        a.button {
          @include gradient-h;
          width: auto;
          max-width: 260px;
          margin: 16px auto 0;
          color: #fff;
          display: block;
        }
      }
    }
  }
}

.l-intro {
  @extend %gradient-light-v;
  @extend %layout-section;

  &__inner {
    @extend %layout-column;
    .section-title {
      @extend %text-section-title;
      margin: 0 auto 48px;
      &--pc {
        @include tablet {
          display: none;
        }
      }
      &--mobile {
        display: none;
        @include tablet {
          display: block;
          margin: 16px auto;
        }
      }
    }
    .main-image {
      width: 100%;
    }
    .c-icon-list {
      margin-top: 48px;
    }
  }
}

@include mobile {
  .l-intro {
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 0.01%, #eef6fc 100%);
    &__inner {
      padding: 40px 16px;
      box-sizing: border-box;
      .section-title {
        font-size: $font-size-medium;
        line-height: $line-height-default;
      }

      .c-icon-list {
        margin-top: 0;
      }
    }
  }
}

.l-strengths {
  @extend %gradient-h;
  @extend %layout-section;

  &__inner {
    @extend %layout-column;

    .tab-section {
      background-color: rgba(#fff, 1);
      width: 100%;
      box-sizing: border-box;
      padding: 48px;
      border-radius: 8px;
      margin-top: 64px;
      @include mobile {
        padding: 24px 16px;
      }
      &__title {
        font-size: $font-size-medium;
        text-align: center;
        color: $color-blue;
      }
    }
  }

  .section-title {
    @extend %text-section-title;
    color: #fff;
  }
}

.l-voice {
  @extend %layout-section;
  .section-title {
    @extend %text-section-title;
  }
  &__inner {
    @extend %layout-column;
    .voice-list {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 80px;
      list-style: none;
      @include tablet {
        grid-column-gap: 40px;
        padding: 0 24px;
      }
      @include mobile {
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-row-gap: 40px;
        padding-left: 40px;
      }
    }
    .item {
      padding: 0;
      .quote {
        line-height: $line-height-default;
        color: $color-blue;
        font-size: $font-size-medium;
        font-weight: normal;
        position: relative;
        margin-top: 0;
        img {
          position: absolute;
          left: -40px;
        }
        @include tablet {
          img {
            width: 16px;
            left: -24px;
          }
        }
      }
      .label {
        font-size: $font-size-default;
        margin: auto;
        color: $color-text-default;
      }
      .detail {
        @extend %text-body;
      }
    }
  }
}

.l-flow {
  @extend %layout-section;
  @extend %gradient-light-h;
  position: relative;
  .section-title {
    @extend %text-section-title;
  }
  .section-arrow {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -32px;
  }
  &__inner {
    @extend %layout-column;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
    align-items: center;
    @include tablet {
      grid-template-columns: auto;
      grid-row-gap: 16px;
      width: 100%;
    }
  }
  .image {
    @include tablet {
      width: 100%;
      max-width: 300px;
      height: auto;
      margin: auto;
      order: 0;
    }
  }
  .step-list {
    list-style: none;
    padding: 0;
    @include tablet {
      order: 1;
    }
    li {
      border-bottom: 1px dashed $color-blue;
      display: grid;
      grid-template-columns: 46px 144px auto;
      grid-column-gap: 16px;
      align-items: center;
      @include tablet {
        padding: 16px 0;
      }
      @include mobile {
        grid-template-columns: 36px 90px auto;
      }
      .icon {
        width: 100%;
      }
      .step {
        color: $color-blue;
        font-size: $font-size-base;
        margin: 0 auto 8px;
        img {
          margin-right: 4px;
        }
      }
      .lead {
        color: $color-blue;
        font-size: $font-size-default;
        margin: 0 auto;
        line-height: $line-height-default;
      }
      .detail {
        color: $color-text-default;
        font-size: $font-size-default;
        line-height: $line-height-wide;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        max-width: 100%;
      }
    }
  }
}

.l-register {
  @extend %layout-section;
  @extend %gradient-h;
  position: relative;
  padding: 200px 0;
  &__background {
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    opacity: 0.1;
  }
  &__inner {
    @extend %layout-column;
    position: relative;
    z-index: 1;
    @include mobile {
      padding: 0 24px;
    }
    .section-title {
      font-size: $font-size-xlarge;
      line-height: $line-height-default;
      text-align: center;
      color: #fff;
      @include tablet {
        font-size: $font-size-large;
        br {
          display: none;
        }
      }
    }
    a.button {
      @extend %glow;
      background-color: #fff;
      text-align: center;
      text-decoration: none;
      color: $color-blue;
      line-height: 56px;
      width: 200px;
      margin: auto;
      display: block;
      border-radius: 28px;
      transition: opacity 0.25s;
      &:hover {
        opacity: 0.75;
        box-shadow: none;
      }
    }
  }
}

.l-about {
  @extend %layout-section;
  border-top: 1px solid $color-border;

  &__inner {
    @extend %layout-column;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 48px;
    padding-bottom: 48px;
    @include tablet {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
      padding: 16px;
    }
    &:before {
      position: absolute;
      z-index: 0;
      content: "";
      background-color: rgba($color-blue, 0.05);
      border-radius: 4px;
      display: block;
      bottom: 0;
      top: 0;
      left: 40px;
      right: 0;
      @include tablet {
        left: 0;
      }
    }
    .image {
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        border-radius: 4px;
      }
    }
    .content {
      position: relative;
      z-index: 1;
      color: $color-blue;
      padding: 0 64px;
      @include tablet {
        padding: 0;
      }
      .subtitle {
        font-size: $font-size-base;
        margin: 0 auto 0.5em;
      }
      .title {
        font-size: $font-size-xlarge;
        margin: auto;
        @include tablet {
          font-size: $font-size-large;
        }
      }
      .details {
        @extend %text-body;
        color: $color-blue;
        a {
          color: $color-blue;
          font-weight: bold;
        }
      }
    }
  }
}
